<template>
  <div v-if="metric > 0 && !item.creativePlanId && !item.keywordPlanId">
    <t-button-inline :pt:root:style="{ fontSize: '0.65rem', gap: '0.1rem' }"
                     severity="info"
                     class="ml-1"
                     @click="showModal = true">
      <fluency-icon type="search" /> detail
    </t-button-inline>
    <t-modal v-model="showModal" title="Conversion Detail" size="mostly-full" ok-only>
      <div style="height:calc(100vh - 260px);" class="position-relative w-100" v-if="showModal && conversions.length > 0">
        <fluent-table :items="conversions" :fields="fields" :show-download-button="true" plan-type="conversions">
          <template #cell(customAdGroupPlanName)="row">
            <span v-p-tooltip.top="row.item.adGroupPlanName">{{row.item.adGroupPlanName}}</span>
          </template>
          <template #cell(customCampaignPlanName)="row">
            <span v-p-tooltip.top="row.item.campaignPlanName">{{row.item.campaignPlanName}}</span>
          </template>
          <template #cell(conversionName)="row">
            <span v-p-tooltip.top="row.item.conversionName">{{row.item.conversionName}}</span>
          </template>
        </fluent-table>
      </div>
    </t-modal>
  </div>
</template>

<script>

export default {
  name: 'conversionBreakdown',
  components: {},
  props: ['title', 'metric', 'item'],
  data () {
    return {
      showModal: false,
      conversions: [],
      fields: [
        'day',
        'network',
        { key: 'campaignPlanName', label: 'Campaign', class: 'text-left truncate', sortable: true, defaultWidth: 400 },
        { key: 'customAdGroupPlanName', label: 'Ad Group', class: 'text-left truncate', sortable: true, defaultWidth: 400 },
        {
          key: 'conversionName',
          defaultWidth: 500,
          class: 'text-left truncate'
        },
        {
          key: 'conversionCategory',
          defaultWidth: 150
        },
        'conversions',
        'convRate',
        'cost_Conv',
        'partnerName']
    }
  },
  watch: {
    showModal (newValue, oldValue) {
      if (newValue) {
        this.loadConversionBreakdown()
      }
    }
  },
  methods: {
    async loadConversionBreakdown () {
      const payload = {
        accountPlanId: this.item.accountPlanId
      }
      let type = 'account'

      if (this.item.campaignPlanId) {
        payload.campaignPlanId = this.item.campaignPlanId
        type = 'campaign'
      }
      if (this.item.adGroupPlanId) {
        payload.adGroupPlanId = this.item.adGroupPlanId
        type = 'adgroup'
      }
      const response = await this.$res.fetch.conversionDetail(payload, type, this.getMetricsDateParams())
      this.$setCompat(this, 'conversions', response)
    },
    getMetricsDateParams () {
      const [startDateTime, endDateTime] = this.$store.getters.timeRange
      let params = ''

      if (startDateTime && endDateTime) {
        params += '?metricsStart=' + this.$moment(startDateTime).format('YYYY-MM-DD')
        params += '&metricsEnd=' + this.$moment(endDateTime).format('YYYY-MM-DD')
      }

      return params
    }
  }
}
</script>

<style scoped>
</style>
